import React from 'react';
import styles from './Header.module.scss'; 

const Header = () => {
  return (
    <header className={styles.header}>
      <h1>Markel Yu</h1>
      <p>Software Developer</p>
    </header>
  );
};

export default Header;
