import React, { useEffect, useState } from 'react';

const Project = () => {
    const [projects, setProjects] = useState([]);
    const [error, setError] = useState(null);
   
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await fetch('http://localhost:5000/projects');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setProjects(data);
            } catch (error) {
                console.error('Error fetching projects:', error);
                setError(error.message);
            }
        };
    
        fetchProjects();
    }, []);
    
    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <h3>Projects</h3>
            <ul>
                {projects.map(project => (
                    <li key={project.id}>
                        <div>
                            <p><strong>{project.name}</strong></p>
                            <p>{project.description}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Project;