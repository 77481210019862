import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Smath from './components/Smath';
import NotFound from './components/NotFound';
import PrivacyPolicy from './components/PrivacyPolicy';
import Header from './components/Header';
import Footer from './components/Footer';

import styles from './App.module.scss';

const App = () => {
  return (
    <div className={styles.app}>
      <Header/>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects/smath" element={<Smath />} />
          <Route path="/projects/smath/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <Footer/>
    </div>
  );
};
export default App;
